
import { useToggle } from '@/composables/useToggle'
import { defineComponent } from 'vue'
import BaseModal from '../base/BaseModal.vue'

export default defineComponent({
  components: { BaseModal },
  props: {
    template: {
      type: Object,
      default: () => ({})
    },
    hideSelection: {
      type: Boolean,
      default: false
    },
  },

  emits: ['onToogle'],

  setup (props, { emit }) {
    const {
      isActive: open,
      setInactive: close
    } = useToggle(false)
    
    const toogle = (id: string) => {
      emit('onToogle', id)
      close()
    }

    return {
      open,
      close,
      toogle
    }
  }
})
