
import { defineComponent, onMounted } from 'vue'
import { useTemplates } from '@/composables/templates/useTemplates';
import TemplateThumbnail from '@/components/templates/templateThumbnail.vue'
import BasePopover from '@/components/base/BasePopover.vue'

export default defineComponent({
  components: {
    TemplateThumbnail,
    BasePopover
  },

  setup () {
    const {
      isLoaded,
      isLoading,
      templates,
      selected,
      fetchTemplates,
      toogle
    } = useTemplates()

    onMounted(() => {
      if (!templates.value.length) {
        fetchTemplates({
          skip: 0
        })
      }
    })

    return {
      isLoaded,
      isLoading,
      templates,
      selected,
      toogle
    }
  }
})
